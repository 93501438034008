import  firebase from "firebase";
import "firebase/auth";

const db = firebase.initializeApp({
    apiKey: "AIzaSyAXVwNYADsetJArQsOi3i8muoe6HGWNNkc",
    authDomain: "vdh-adh.firebaseapp.com",
    projectId: "vdh-adh",
    storageBucket: "vdh-adh.appspot.com",
    messagingSenderId: "185173262492",
    appId: "1:185173262492:web:94450e7b733db55b626d59",
    measurementId: "G-DG7H7QYL52"
});
export default db;

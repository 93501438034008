import React, {useEffect, useState} from "react";
import db from "./config";

export const AuthContext = React.createContext();


export const AuthProvider = ({children}) => {
    const [user, setUser] = useState(null);

    useEffect(() => {
        db.auth().onAuthStateChanged(setUser);
    }, []);

    return (
        <AuthContext.Provider value={{user}}>{children}</AuthContext.Provider>
    );
}

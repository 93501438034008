import React, {Component} from 'react';
import {HashRouter, Route, Switch} from 'react-router-dom';
// import { renderRoutes } from 'react-router-config';
import './App.scss';
import {AuthProvider} from "./config/context";
import { logout } from "./actions/auth";
import { clearMessage } from "./actions/message";
import { connect } from "react-redux";
import { history } from './helpers/history';
const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;

// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));

// Pages
const Login = React.lazy(() => import('./views/Pages/Login'));
const Register = React.lazy(() => import('./views/Pages/Register'));
const Page404 = React.lazy(() => import('./views/Pages/Page404'));
const Page500 = React.lazy(() => import('./views/Pages/Page500'));

class App extends Component {

    render() {

        return (
            <AuthProvider>
                <HashRouter history={history}>
                    <React.Suspense fallback={loading()}>
                        <Switch>
                            <Route exact path="/" name="Login Page" render={props => <Login {...props}/>}/>
                            <Route exact path="/register" name="Register Page"
                                   render={props => <Register {...props}/>}/>
                            <Route exact path="/404" name="Page 404" render={props => <Page404 {...props}/>}/>
                            <Route exact path="/500" name="Page 500" render={props => <Page500 {...props}/>}/>
                            <Route path="/home" name="Home" render={props => <DefaultLayout {...props}/>}/>
                        </Switch>
                    </React.Suspense>
                </HashRouter>
            </AuthProvider>
        );
    }
}
function mapStateToProps(state) {
    const { user } = state.auth;
    return {
        user,
    };
}

export default connect(mapStateToProps)(App);
